import { defineStore } from "pinia"
import axiosInstance from "@/http.js"
const axios = axiosInstance


export const CLEARFXAI_API_URL = process.env.VUE_APP_CLEARFXAI_API_URL
export const APIKEY = process.env.VUE_APP_APIKEY
export const APIVERSION = process.env.VUE_APP_API_VERSION
export const APP_PROD = process.env.VUE_APP_PROD

export const API_PATH = `v${APIVERSION}/clearfxai`

export let AUTH_PATH = `apiauth/${API_PATH}`
export let CONSOLE_AUTOPRICER_PATH = `consoleautopricer`

// if (process.env.NODE_ENV === "development"){
//   AUTH_PATH = `${API_PATH}`
//   // CONSOLE_AUTOPRICER_PATH = ""
// }

export const useUtilsStore = defineStore("utils", {
  state: () => ({}),
  actions: {},
});

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: undefined,
    userId: undefined,
    apiKey: undefined
  }),
  getters: {
    isAuthenticated: (state) => state.authenticated,
    getUserId: (state) => state.userId,
    getApiKey: (state) => state.apiKey,
  },
  actions: {
    login (payload) {
      const url = `${CLEARFXAI_API_URL}/${AUTH_PATH}/login`
      return axios.post(url, payload)
    },
    getAuthenticated () {
      this.loadAuthenticatedAndUserIdStateFromLocalStorage()
      const userId = this.getUserId
      const url = `${CLEARFXAI_API_URL}/${AUTH_PATH}/${userId}`
      return axios.get(url)
    },
    logout () {
      const userId = this.getUserId
      const url = `${CLEARFXAI_API_URL}/${AUTH_PATH}/logout/${userId}`
      return axios.delete(url)
    },
    loadAuthenticatedAndUserIdStateFromLocalStorage () {
      let userId = localStorage.getItem("userId")
      let authenticated = false
      if (userId) {
        authenticated = true
      }
      this.authenticated = authenticated
      this.userId = userId
    },
    saveSessionLocalStorage (data) {
      localStorage.setItem("userId", data.user_id)
    },
    removeSessionLocalStorage () {
      localStorage.removeItem("userId")
      localStorage.removeItem("apiKey")
    },
    getSessionFromLocalStorage () {
      const data = localStorage.getItem("userId")
      return data
    },
  },
});

export const useDisplayStore = defineStore("display", {
  state: () => ({
    breakpoint: undefined,
    windowHeight: window.innerHeight,
    headerHeight: 153,
    // footerHeight: 30,
    marginLayout: 32,
  }),
  getters: {
    isMobile() {
      return this.mobile
    },
    currentBreakpoint () {
      return this.breakpoint
    },
    isXLarge () {
      return this.currentBreakpoint === "xl"
    },
    isLarge () {
      return this.currentBreakpoint === "lg"
    },
    isMedium () {
      return this.currentBreakpoint === "md"
    },
    isSmall () {
      return this.currentBreakpoint === "sm"
    },
    isXSmall () {
      return this.currentBreakpoint === "xs"
    },
    contentHeight () {
      const contentHeight = 
        this.windowHeight 
        - this.headerHeight 
        // - this.footerHeight 
        // - this.marginLayout // x axis
        // - this.marginLayout // y axis
      return `${contentHeight}`
    },
  },
  actions: {
  }
})

export const useConsoleAutopricerStore = defineStore("consoleAutopricer", {
  state: () => ({}),
  actions: {
    getMarketData () {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/marketdata`
      return axios.get(url)
    },
    getDealEmailAddress () {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/deal_email_address`
      return axios.get(url)
    },
    putDealEmailAddress (payload) {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/deal_email_address`
      return axios.put(url, payload)
    },
    getDealCount () {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/dealcount`
      return axios.get(url)
    },
    getSpreadsCategory () {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/spreads_category`
      return axios.get(url)
    },
    putSpreadsCategory (payload) {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/spreads_category`
      return axios.put(url, payload)
    },
    getSpreadsNotional () {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/spreads_notional`
      return axios.get(url)
    },
    putSpreadsNotional (payload) {
      const url = `${CLEARFXAI_API_URL}/${CONSOLE_AUTOPRICER_PATH}/spreads_notional`
      return axios.put(url, payload)
    },
  }
});