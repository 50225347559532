<template>
  <div>
    <v-row no-gutters class="mb-8 px-8">
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>

      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        class="pt-8"
      >
        <div class="d-flex flex-column">
          <div class="d-flex mb-16 pb-8">
            <div class="text-h3 font-weight-light">
              {{ $t('title.fxEngine') }}
            </div>
            <v-spacer></v-spacer>
            <div class="font-weight-light">
              {{ now }}
            </div>
          </div>

          <v-row no-gutters>
            <v-col 
              xl="6"
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="d-flex flex-column align-center"
            >
              <div class="d-flex flex-column">
                <div class="text-h5 font-weight-light">
                  {{ $t('fxEngine.spotD2') }}
                </div>
                <div class="text--disabled font-weight-light">
                  {{ $t('fxEngine.lastUpdate') }} {{ marketdata.lastupdate }}
                </div>
              </div>
              <!-- <donut-chart></donut-chart> -->
              <v-row style="height: 350px;" class="align-center">
                <v-col cols="6">
                  <!-- Ask <br> -->
                  <div class="d-flex py-5 align-center">
                    <div class="mr-1 text-h4 font-weight-bold"> {{marketdata.ask.wdo1}} </div> 
                    <div class="d-flex flex-column text-body-2 text--secondary"> 
                      <span class="font-weight-bold"> {{ $t('fxEngine.WDOJ24') }} </span>
                      <span class="attention--text"> {{ $t('fxEngine.ask') }} </span> 
                    </div>
                  </div>
                  <div class="d-flex py-5 align-center">
                    <div class="mr-1 text-h4 font-weight-bold"> {{marketdata.ask.csd2}} </div> 
                    <div class="d-flex flex-column text-body-2 text--secondary">
                      <span class="font-weight-bold"> {{ $t('fxEngine.casadoUsd') }} </span>
                      <span class="attention--text"> {{ $t('fxEngine.ask') }} </span>
                    </div>
                  </div>
                  <div class="d-flex py-5 align-center">
                    <div class="mr-1 text-h4 font-weight-bold"> {{marketdata.ask.usdbrl}} </div> 
                    <div class="d-flex flex-column text-body-2 text--secondary"> 
                      <span class="font-weight-bold"> {{ $t('fxEngine.USDBRL') }} </span>
                      <span class="attention--text"> {{ $t('fxEngine.ask') }} </span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <!-- Bid <br> -->
                  <div class="d-flex py-5 align-center">
                    <div class="mr-1 text-h4 font-weight-bold"> {{marketdata.bid.wdo1}} </div> 
                    <div class="d-flex flex-column text-body-2 text--secondary"> 
                      <span class="font-weight-bold"> {{ $t('fxEngine.WDOJ24') }} </span>
                      <span class="alive--text"> {{ $t('fxEngine.bid') }} </span>
                    </div>
                  </div>
                  <div class="d-flex py-5 align-center">
                    <div class="mr-1 text-h4 font-weight-bold"> {{marketdata.bid.csd2}} </div> 
                    <div class="d-flex flex-column text-body-2 text--secondary">
                      <span class="font-weight-bold"> {{ $t('fxEngine.casadoUsd') }} </span>
                      <span class="alive--text"> {{ $t('fxEngine.bid') }} </span>
                    </div>
                  </div>
                  <div class="d-flex py-5 align-center">
                    <div class="mr-1 text-h4 font-weight-bold"> {{marketdata.bid.usdbrl}} </div> 
                    <div class="d-flex flex-column text-body-2 text--secondary">
                      <span class="font-weight-bold"> {{ $t('fxEngine.USDBRL') }} </span>
                      <span class="alive--text"> {{ $t('fxEngine.bid') }} </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col 
              xl="6"
              lg="6"
              md="12"
              sm="12"
              xs="12"
              class="d-flex"
            >
              <v-divider vertical inset class="py-2" v-if="isXLarge || isLarge"></v-divider>

              <div class="d-flex flex-column mx-auto" style="max-width: 480px; height: 100%;">
                <div class="d-flex flex-column mb-8">
                  <div class="text-h5 font-weight-light">
                    {{ $t('fxEngine.operationalSummary').toUpperCase() }}
                  </div>
                  <div class="text--disabled font-weight-light">
                    {{ $t('fxEngine.lastSevenDays') }}
                  </div>
                </div>

                <div
                  class="my-auto d-flex flex-column fill-height"
                  style="justify-content: space-evenly;"
                >
                  <div class="text-h2 mt-0 ml-3 font-weight-light mb-4">
                    {{ dealCount }} <span class="text-subtitle-1 font-weight-light"> {{ $t('fxEngine.deals').toUpperCase() }} </span>
                  </div>

                  <div class="text-h2 mb-0 ml-3 font-weight-light d-flex align-center">
                    <!-- <div class="mr-3"> -->
                      <!-- <span class="text-subtitle-1 font-weight-light"> DEAL destination address 
                      <pre>{{ APIKEY }}</pre>
                      </span> -->
                    <!-- </div> -->

                    <v-text-field
                      v-model="dealDestinationAddress"
                      :label="$t('fxEngine.dealDestination')"
                      placeholder="address@email.com"
                      class="mr-2"
                      style="width: 220px;"
                      readonly
                      persistent-hint
                      :hint="$t('extras.readOnly')"
                    >
                    </v-text-field>

                      <!--
                    <v-btn
                      disabled
                      elevation="0"
                      tile
                      color="blue"
                    >
                      SAVE
                    </v-btn>
                      -->
                    <!-- <div class="mr-3">
                    </div> -->
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
    </v-row>
    
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet
          width="100%"
          class="py-12 px-8"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="pt-4 d-flex flex-column"
            >
              <div class="text-h5 font-weight-light mb-10">
                {{ $t('fxEngine.spreads.title') }}
              </div>

              <v-card outlined>
                <v-tabs v-model="spreadsTab" color="white" slider-color="primary" :slider-size="5">
                  <v-tab>
                    {{ $t('fxEngine.spreads.categories.title') }}
                    
                    <!--
                    <v-btn outlined 
                      small 
                      color="white"
                      disabled
                    >
                      <v-icon
                        left
                        color="white"
                      >
                        mdi-plus
                      </v-icon>
                      ADD CERTIFICATE
                    </v-btn>
                    -->
                  </v-tab>
                  <v-tab>
                    {{ $t('fxEngine.spreads.notional') }}

                    <!--
                    <v-btn outlined 
                      small 
                      color="white"
                      disabled
                    >
                      <v-icon
                        left
                        color="white"
                      >
                        mdi-plus
                      </v-icon>
                      ADD CERTIFICATE
                    </v-btn>
                    -->
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="spreadsTab">
                  <v-tab-item>
                    <v-simple-table style="border-top: 4px solid #121212;">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.status.title') }}
                            </th>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.category') }}
                            </th>
                            <th 
                              class="text-left"
                            >
                              <!-- style="max-width: 400px; width: 400px" -->
                              {{ $t('fxEngine.spreads.valueBps') }}
                            </th>
                            <th class="text-left">
                              -
                            </th>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.lastUpdate') }}
                            </th>
                            <th class="text-left">
                              -
                            </th>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.edit.title').toUpperCase() }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in spreadsCategory"
                            :key="index"
                            style="background-color: #121212;"
                          >
                            <td
                              :class="{
                                'green--text text--accent-4': item.status && item.status.toLocaleLowerCase() === 'active'
                              }"
                            >
                              {{ spreadStatus(item.status) }}
                            </td>
                            <td>{{ spreadCategory(item.category) }}</td>
                            <td>{{ item.value }}</td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  -
                                  <!-- {{ item.timecreate | formatDate }} -->
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.certificate_creation_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timelast | formatDate }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.last_connection_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  -
                                  <!-- {{ item.timedue | formatDate }} -->
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.expiration_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <v-btn
                                icon
                                class="ml-3"
                                @click="editSpreadCategory(item)"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>

                  <v-tab-item>
                    <v-simple-table style="border-top: 4px solid #121212;">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.status.title') }}
                            </th>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.notional') }}
                            </th>
                            <th 
                              class="text-left"
                            >
                              <!-- style="max-width: 400px; width: 400px" -->
                              {{ $t('fxEngine.spreads.valueBps') }}
                            </th>
                            <th class="text-left">
                              -
                            </th>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.lastUpdate') }}
                            </th>
                            <th class="text-left">
                              -
                            </th>
                            <th class="text-left">
                              {{ $t('fxEngine.spreads.edit.title').toUpperCase() }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in spreadsNotional"
                            :key="index"
                            style="background-color: #121212;"
                          >
                            <td
                              :class="{
                                'green--text text--accent-4': item.status && item.status.toLocaleLowerCase() === 'active'
                              }"
                            >
                              {{ spreadStatus(item.status) }}
                            </td>
                            <td>{{ item.notional }}</td>
                            <td>{{ item.value }}</td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  -
                                  <!-- {{ item.timecreate | formatDate }} -->
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.certificate_creation_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  {{ item.timelast | formatDate }}
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.last_connection_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <div class="d-flex flex-column">
                                <span>
                                  -
                                  <!-- {{ item.timedue | formatDate }} -->
                                </span>
                                <!-- <span class="text--disabled">
                                  {{ item.expiration_date }}
                                </span> -->
                              </div>
                            </td>
                            <td>
                              <v-btn
                                icon
                                class="ml-3"
                                @click="editSpreadNotional(item)"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>

            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogSpreadsCategory"
      max-width="500px"
      persistent
    >
      <v-card :disabled="loadingSpreadCategoryPut">
        <v-card-title>
          {{ spreadCategory(spreadCategoryPayload.category) }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-6">
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                type="number"
                hide-spin-buttons
                v-model="spreadCategoryPayload.value"
                :label="$t('fxEngine.spreads.edit.spread')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <div>
                <span class="text-caption">
                  {{ $t('fxEngine.spreads.edit.status') }}
                </span>
                <v-switch
                  class="mt-0"
                  v-model="spreadCategoryPayload.status"
                  :label="$t('fxEngine.spreads.edit.active')"
                ></v-switch>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogSpreadsCategory = false"
          >
            {{ $t('instructions.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            tile
            @click="saveSpreadCategory()"
            :loading="loadingSpreadCategoryPut"
          >
            {{ $t('instructions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogSpreadsNotional"
      max-width="500px"
      persistent
    >
      <v-card :disabled="loadingSpreadNotionalPut">
        <v-card-title>
          {{ spreadNotionalPayload.notional }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-6">
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                type="number"
                hide-spin-buttons
                v-model="spreadNotionalPayload.value"
                :label="$t('fxEngine.spreads.edit.spread')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <div>
                <span class="text-caption">
                  {{ $t('fxEngine.spreads.edit.spread') }}
                </span>
                <v-switch
                  class="mt-0"
                  v-model="spreadNotionalPayload.status"
                  :label="$t('fxEngine.spreads.edit.active')"
                ></v-switch>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                type="number"
                hide-spin-buttons
                v-model="spreadNotionalPayload.notional"
                :label="$t('fxEngine.spreads.edit.notional')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogSpreadsNotional = false"
          >
            {{ $t('instructions.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            tile
            @click="saveSpreadNotional()"
            :loading="loadingSpreadNotionalPut"
          >
            {{ $t('instructions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      color="green accent-4"
      :timeout="4000"
      bottom
    >
      <span class="black--text">
        {{ $t('fxEngine.spreads.edit.updatedSucces') }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
import { useDisplayStore, useConsoleAutopricerStore } from "./../store/index"
import { mapState, mapActions } from "pinia";
import * as utils from "./../utils.js"

export default {
  components: {
  },
  filters: {
    formatDate (timestamp) {
      return utils.getLocaleFormattedDateTime(timestamp)
    }
  },
  data () {
    return {
      spreadsTab: 0,
      spreadsCategory: [],
      spreadsNotional: [],
      marketdata: {
        bid:{
          "csd2": 0.0000,
          "usdbrl": 0.0000,
          "wdo1": 0.0000
        },
        ask:{
          "csd2": 0.0000,
          "usdbrl": 0.0000,
          "wdo1": 0.0000
        },
        lastupdate: "-"
      },
      dealDestinationAddress: "",
      dealCount: 0,
      dialogSpreadsCategory: false,
      dialogSpreadsNotional: false,
      spreadCategoryPayload: {
        value: undefined,
        status: undefined
      },
      spreadNotionalPayload: {
        value: undefined,
        status: undefined
      },
      loadingSpreadCategoryPut: false,
      loadingSpreadNotionalPut: false,
      snackbar: false,
      interval: null
    }
  },
  computed: {
    ...mapState(useDisplayStore, [
      "isXSmall",
      "isSmall",
      "isMedium",
      "isLarge",
      "isXLarge"
    ]),
    now () {
      return utils.getLocaleFormattedDateTime()
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.getMarketData()
        .then((response) => {
          const data = response.data
          let lastUpdate = data.lastupdate
          lastUpdate = utils.getLocaleFormattedTime(lastUpdate)
          data["lastupdate"] = lastUpdate
          this.marketdata = data
        })
        .catch((error) => {
          console.log(error)
        })
    }, 1000);
    this.getDealCount()
      .then((response) => {
        const data = response.data
        this.dealCount = data.deal_count
      })
      .catch((error) => {
        console.log(error)
      })
    this.spreadsCategoryFetch()
    this.spreadsNotionalFetch()
    this.getDealEmailAddress()
      .then((response) => {
        const data = response.data
        this.dealDestinationAddress = data.deals_email_address
      })
      .catch((error) => {
        console.log(error)
      })
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions(useConsoleAutopricerStore, [
      "getMarketData",
      "getDealEmailAddress",
      "getDealCount",
      "getSpreadsCategory",
      "putDealEmailAddress",
      "putSpreadsCategory",
      "getSpreadsNotional",
      "putSpreadsNotional"
    ]),
    spreadsCategoryFetch () {
      this.getSpreadsCategory()
        .then((response) => {
          const data = response.data
          this.spreadsCategory = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    spreadsNotionalFetch () {
      this.getSpreadsNotional()
        .then((response) => {
          const data = response.data
          this.spreadsNotional = data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    editSpreadCategory (item) {
      this.spreadCategoryPayload = JSON.parse(JSON.stringify(item))
      let status = false
      if (this.spreadCategoryPayload.status === "Active") {
        status = true
      }
      this.spreadCategoryPayload.status = status
      this.dialogSpreadsCategory = true
    },
    editSpreadNotional (item) {
      this.spreadNotionalPayload = JSON.parse(JSON.stringify(item))
      let status = false
      if (this.spreadNotionalPayload.status === "Active") {
        status = true
      }
      this.spreadNotionalPayload.status = status
      this.dialogSpreadsNotional = true
    },
    saveSpreadCategory () {
      const payload = JSON.parse(JSON.stringify(this.spreadCategoryPayload))
      let status = "Skip"
      if (payload.status) {
        status = "Active"
      }
      payload["status"] = status
      payload["value"] = Number(payload["value"])
      this.loadingSpreadCategoryPut = true
      this.putSpreadsCategory(payload)
        .then(() => {
          this.snackbar = true
          this.spreadsCategoryFetch()
          this.dialogSpreadsCategory = false
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingSpreadCategoryPut = false
        })
    },
    saveSpreadNotional () {
      const payload = JSON.parse(JSON.stringify(this.spreadNotionalPayload))
      let status = "Skip"
      if (payload.status) {
        status = "Active"
      }
      payload["status"] = status
      payload["value"] = Number(payload["value"])
      this.loadingSpreadNotionalPut = true
      this.putSpreadsNotional(payload)
        .then(() => {
          this.snackbar = true
          this.spreadsNotionalFetch()
          this.dialogSpreadsNotional = false
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingSpreadNotionalPut = false
        })
    },
    spreadStatus (status) {
      if (status.toLowerCase() === 'active') {
        return this.$i18n.t('fxEngine.spreads.status.active')
      }
      else {
        return this.$i18n.t('fxEngine.spreads.status.skip')
      }
    },
    spreadCategory (category) {
      if (category === 'COMERCIO_EXTERIOR') {
        return this.$i18n.t('fxEngine.spreads.categories.foreignTrade')
      }
      else if (category === 'CAPITAIS_ESTRANGEIROS') {
        return this.$i18n.t('fxEngine.spreads.categories.foreignCapital')
      }
      else if (category === 'RENDAS_CAPITAIS') {
        return this.$i18n.t('fxEngine.spreads.categories.capitalGains')
      }
      else if (category === 'SEGUROS') {
        return this.$i18n.t('fxEngine.spreads.categories.insurance')
      }
      else if (category === 'SERVICOS_DIVERSOS') {
        return this.$i18n.t('fxEngine.spreads.categories.micellaneousServices')
      }
      else {
        return this.$i18n.t('fxEngine.spreads.categories.transport')
      }
    }
  }
}
</script>
